<template>
  <div>
    <div>
      <div>
        <breadcrumb name="Categories" title="Categories List" subtitle="Add New" @onTitleClicked="viewList"/>
      </div>
      <div class="body">
        <div>
          <h2>Category Info</h2>
        </div>
        <vs-row style="padding-right: 20px; padding-left: 20px">

          <vs-col vs-lg="12" vs-sm="12">
            <choose-model @onBrandChosen="brand_id = $event" @onModelChosen="model_id = $event"/>
          </vs-col>

          <!--          <vs-col vs-lg="6" vs-sm="12">-->
          <!--            <div class="drop-down">-->
          <!--              <label for="brands">Choose Brand</label>-->
          <!--              <select id="brands" v-model="brand_id" style="height: 40px">-->
          <!--                <option value="0" selected disabled>Choose Brand</option>-->
          <!--                <option v-for="(brand, index) in brands" :value="brand.id" :key="index">{{brand.name_en}}</option>-->
          <!--              </select>-->
          <!--            </div>-->
          <!--          </vs-col>-->
          <!--          <vs-col vs-lg="6" vs-sm="12">-->
          <!--            <div class="drop-down">-->
          <!--              <label for="models">Choose Model</label>-->
          <!--              <select id="models" v-model="model_id" style="height: 40px">-->
          <!--                <option value="0" selected disabled>Choose Model</option>-->
          <!--                <option v-for="(model, index) in modelsOfBrand" :value="model.id" :key="index">{{model.name_en}}-->
          <!--                </option>-->
          <!--              </select>-->
          <!--            </div>-->
          <!--          </vs-col>-->

        </vs-row>
        <vs-row style="padding-right: 20px; padding-left: 20px">
          <vs-col vs-lg="6" vs-md="12">
            <vs-input label="Category Name English" v-model="name_en" size="medium"
                      class="input" danger-text="This field is mandatory"/>
          </vs-col>
          <vs-col vs-lg="6" vs-md="12">
            <vs-input label="Category Name Arabic" v-model="name_ar" size="medium"
                      class="input" danger-text="This field is mandatory"/>
          </vs-col>

          <vs-col vs-lg="6" vs-sm="12">
            <div class="drop-down">
              <label for="slider">Attach Featured Gallery</label>
              <select id="slider" v-model="slider_id" style="height: 40px">
                <option value="0" selected disabled>Choose Gallery</option>
                <option v-for="(gallery, index) in galleries" :value="gallery.id" :key="index">{{gallery.name}}
                </option>
              </select>
            </div>
          </vs-col>

          <vs-col vs-lg="6" vs-sm="12">
            <div class="drop-down">
              <label for="category">Attach Category Gallery</label>
              <select id="category" v-model="gallery_id" style="height: 40px">
                <option value="0" selected disabled>Choose Gallery</option>
                <option v-for="(gallery, index) in galleries" :value="gallery.id" :key="index">{{gallery.name}}
                </option>
              </select>
            </div>
          </vs-col>

          <vs-col vs-lg="6" vs-md="12">
            <vs-input label="Price" v-model="price" size="medium" type="number"
                      class="input" :danger="flags.price" danger-text="Price must be less than 9 digits!"/>
          </vs-col>

<!--          <vs-col vs-lg="6" vs-md="12">-->
<!--            <div class="switch">-->
<!--              <label>Allow Test Drive?</label>-->
<!--              <vs-switch v-model="test_drive"/>-->
<!--            </div>-->
<!--          </vs-col>-->

          <vs-col vs-lg="6" vs-md="12">
            <div class="switch">
              <label>Show On Mobile</label>
              <vs-switch v-model="mobile_active"/>
            </div>
          </vs-col>
        </vs-row>
        <div class="optionBtns">
          <vs-button class="mr-5 save" icon-pack="feather" icon="icon-save" :disabled="invalidForm"
                     @click="confirmSaveData">Save
          </vs-button>
          <vs-button class="ml-5 cancel" type="border" color="primary" @click='resetData'>
            Cancel
          </vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Breadcrumb from "../../../../components/general/breadcrumb";
  import ImgUpload from "../../../../components/general/img-upload";
  import UploadFile from "../../../../components/uploader/upload-file";
  import ChooseModel from "../../../../components/custom/choose-model";

  export default {
    name: "add-categories",
    components: { ChooseModel, ImgUpload, Breadcrumb, UploadFile },
    data() {
      return {
        spec_id: "0",
        baseImagesURL: "https://mansour-dev.s3.us-east-2.amazonaws.com/",
        brands: [],
        models: [],
        modelsOfBrand: [],
        colors: [],
        name_en: "",
        name_ar: "",
        model_id: "0",
        brand_id: "0",
        price: "",
        description_en: "",
        description_ar: "",
        galleries: [],
        color_images: [],
        test_drive: true,
        mobile_active: true,
        web_active: true,
        gallery_id: "0",
        slider_id: "0",
        flags: {
          price: false
        }
      };
    },
    computed: {
      invalidForm() {

        if (this.price.length > 8) {
          this.flags.price = true;
        } else {
          this.flags.price = false;
        }

        if (this.name_en && this.name_ar && this.brand_id !== "0" && this.model_id !== "0" && this.price.length <= 8 && this.gallery_id !== "0"
          && this.slider_id !== "0") {
          return false;
        }
        return true;
      }
    },
    watch: {
      brand_id(v) {
        this.modelsOfBrand = [];
        for (let i = 0; i < this.models.length; i++) {
          if (v === this.models[i].brand.id) {
            this.modelsOfBrand.push(this.models[i]);
            console.log(this.models[i]);
          }
        }
      }
    },
    methods: {
      getGalleries() {
        this.$httpCars
          .get("gallery-folders")
          .then(r => {
            this.$vs.loading.close();
            let total = r.data.data.total;
            console.log("total", total);
            this.$httpCars
              .get(`gallery-folders?per_page=${total}`)
              .then(r => {
                this.$vs.loading.close();
                this.galleries = r.data.data.data;
                console.log('Galleries: ', this.galleries);
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      },
      viewList() {
        this.$emit("cancel");
      },
      saveImage(imagesArray) {
        this.images = imagesArray;
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      resetData() {
        this.image = null;
        this.$emit("cancel");
      },
      getImage(file) {
        this.images.push(file);
        console.log(this.images);
      },
      saveData() {
        this.$vs.loading();
        let formData = new FormData();

        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("model_id", this.model_id);
        formData.append("price", this.price);
        formData.append("specs", "[]");


        formData.append("test_drive", this.test_drive ? "1" : "0");
        formData.append("mobile_active", this.mobile_active ? "1" : "0");

        formData.append("slider_id", this.slider_id);
        formData.append("category_gallery_id", this.gallery_id);


        this.$httpCars.post(`/categories`, formData)
          .then(r => {
            this.$emit("saved");
            this.$vs.loading.close();
            console.log(r);
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      },
      getModels() {
        this.$vs.loading();
        this.$httpCars.get("models")
          .then(r => {
            this.$vs.loading.close();
            this.models = r.data.data.models;
            console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e)
          });
      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars.get("brands")
          .then(r => {
            this.$vs.loading.close();
            this.brands = r.data.data.brands;
            console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e)
          });
      }
    },
    mounted() {
      this.getBrands();
      this.getModels();
      this.getGalleries();
    }
  };
</script>

<style scoped lang="scss">

  .clr {
    clear: both;
  }

  .body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    label {
      font-size: 1rem;
      padding: 5px;
    }

    select {
      padding: 10px;
      border-radius: 5px;
      width: 90%;
      background-color: #fff;
      border: 1px solid #00000030;
    }

    .inputs {
      margin: 20px 0 20px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    }
  }

  .input {
    width: 90%;
    margin-top: 30px;
  }

  .switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
  }

  .checkboxes {
    margin: 10px;

    .checkbox {
      margin: 5px;
    }
  }

  .optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
  }

  h2 {
    margin: 30px;
  }

  .optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  h4 {
    padding: 20px;
  }

  .subtitle {
    margin-top: 50px;
    color: #ccc;
  }

  .input {
    width: 90%;
    margin-top: 10px;
  }
</style>
