<template>
  <div>
    <div>
      <div>
        <breadcrumb
          name="Categories"
          title="Categories List"
          subtitle="Edit Category"
          @onTitleClicked="viewList"
        />
      </div>
      <div class="body">
        <div>
          <h2>Category Info</h2>
        </div>

        <vs-row style="padding-right: 20px; padding-left: 20px">

          <vs-col vs-lg="12" vs-sm="12">
            <choose-model @onBrandChosen="brand_id = $event" @onModelChosen="model_id = $event"
                          :selectedModelID="model_id"/>
          </vs-col>

        </vs-row>
        <vs-row style="padding-right: 20px; padding-left: 20px">
          <vs-col vs-lg="6" vs-md="12">
            <vs-input
              label="Category Name English"
              v-model="name_en"
              size="medium"
              class="input"
              danger-text="This field is mandatory"
            />
          </vs-col>

          <vs-col vs-lg="6" vs-md="12">
            <vs-input
              label="Category Name Arabic"
              v-model="name_ar"
              size="medium"
              class="input"
              danger-text="This field is mandatory"
            />
          </vs-col>

          <vs-col vs-lg="6" vs-sm="12">
            <div class="drop-down">
              <label for="slider">Attach Featured Gallery</label>
              <select id="slider" v-model="slider_id" style="height: 40px">
                <option value="0" selected disabled>Choose Gallery</option>
                <option
                  v-for="(gallery, index) in galleries"
                  :value="gallery.id"
                  :key="index"
                >{{gallery.name}}
                </option>
              </select>
            </div>
          </vs-col>

          <vs-col vs-lg="6" vs-sm="12">
            <div class="drop-down">
              <label for="category">Attach Category Gallery</label>
              <select id="category" v-model="gallery_id" style="height: 40px">
                <option value="0" selected disabled>Choose Gallery</option>
                <option
                  v-for="(gallery, index) in galleries"
                  :value="gallery.id"
                  :key="index"
                >{{gallery.name}}
                </option>
              </select>
            </div>
          </vs-col>

          <vs-col vs-lg="6" vs-md="12">
            <vs-input
              label="Price"
              v-model="price"
              size="medium"
              class="input"
              danger-text="This field is mandatory"
            />
          </vs-col>

          <vs-col vs-lg="6" vs-md="12">
            <div class="switch">
              <label>Show On Mobile</label>
              <vs-switch v-model="mobile_active"/>
            </div>
          </vs-col>

          <vs-col vs-lg="12" vs-md="12">
            <img-upload
              :single="true"
              :images="image ? [image] : []"
              title="Upload Featured Image"
              @image_uploaded="saveImage"
            />
          </vs-col>
        </vs-row>

        <div class="optionBtns">
          <vs-button
            class="mr-5 save"
            icon-pack="feather"
            icon="icon-save"
            :disabled="invalidForm"
            @click="confirmSaveData"
          >Save
          </vs-button>
          <vs-button
            class="ml-5 cancel"
            type="border"
            color="primary"
            @click="viewList"
          >Cancel
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //TODO:: galleries IDs selected for category don't come with response -> follow up with Kareem Abdellah

  import Breadcrumb from "../../../../components/general/breadcrumb";
  import ImgUpload from "../../../../components/general/img-upload";
  import UploadFile from "../../../../components/uploader/upload-file";
  import ChooseModel from "../../../../components/custom/choose-model";

  export default {
    name: "edit-categories",
    components: { ChooseModel, ImgUpload, Breadcrumb, UploadFile },
    props: {
      item: {
        required: true,
        default: () => {
        }
      }
    },
    data() {
      return {
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        brands: [],
        models: [],
        colors: [],
        specs: [],
        boolSpecs: [],
        textSpecs: [],
        name_en: this.item.name_en,
        name_ar: this.item.name_ar,
        model_id: this.item.model.id,
        brand_id: this.item.model.brand.id,
        price: this.item.price,
        description_en: this.item.description_en,
        description_ar: this.item.description_ar,
        chosen_colors_IDs: [],
        chosen_specs_IDs: [],
        filled_specs_inputs: [],
        galleries: [],
        image: this.item.image,
        color_images: [],
        test_drive: this.item.test_drive,
        mobile_active: this.item.mobile_active,
        web_active: this.web_active,
        gallery_id: this.item.gallery_folder_id,
        slider_id: this.item.slider_folder_id
      };
    },
    watch: {
      mobile_active() {
        this.$httpCars.post(`categories/activation/${this.item.id}/mobile`)
          .then(() => {
            this.$vs.notify({
              title: "Success",
              text: "Category is deactivated successfully.",
              color: "primary"
            });
          })
          .catch(() => {
            // console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't deactivate category!",
              color: "danger"
            });
          });
      }
    },
    computed: {
      modelsOfBrand() {
        let modelsOfBrand = [];
        for (let i in this.models) {
          if (this.brand_id === this.models[i].brand.id) {
            modelsOfBrand.push(this.models[i]);
            //console.log({ models: this.models[i] });
          }
        }
        return modelsOfBrand;
      },
      invalidForm() {
        if (
          this.name_en &&
          this.name_ar &&
          this.brand_id !== "0" &&
          this.model_id !== "0" &&
          this.price &&
          this.gallery_id !== "0" &&
          this.slider_id !== "0"
        ) {
          return false;
        }
        return true;
      }
    },
    methods: {
      getGalleries() {
        this.$httpCars
          .get("gallery-folders")
          .then(r => {
            this.$vs.loading.close();
            let total = r.data.data.total;
            console.log("total", total);
            this.$httpCars
              .get(`gallery-folders?per_page=${total}`)
              .then(r => {
                this.$vs.loading.close();
                this.galleries = r.data.data.data;
                console.log('Galleries: ', this.galleries);
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      },
      viewList() {
        this.$emit("cancel");
      },
      saveImage(images) {
        this.image = images;
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      resetData() {
        this.image = null;
        this.$emit("cancel");
      },
      getImage(file) {
        this.images.push(file);
        //console.log(this.images);
      },
      saveData() {
        this.$vs.loading();

        let specs = [];

        for (let i in this.boolSpecs) {
          for (let x in this.chosen_specs_IDs) {
            let obj;
            if (this.boolSpecs[i].id === this.chosen_specs_IDs[x]) {
              obj = {
                id: this.boolSpecs[i].id,
                value: 1
              };
            } else {
              obj = {
                id: this.boolSpecs[i].id,
                value: 0
              };
            }
            specs.push(obj);
          }
        }

        for (let i in this.filled_specs_inputs) {
          if (this.filled_specs_inputs[i].value !== "") {
            specs.push(this.filled_specs_inputs[i]);
          }
        }

        let formData = new FormData();

        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("model_id", this.model_id);
        formData.append("price", this.price);

        for (let i in specs) {
          formData.append(`specs[${i}][id]`, specs[i].id);
          formData.append(`specs[${i}][value]`, specs[i].value);
        }

        if (this.image) {

          let img = Array.isArray(this.image) ? this.image[0] : this.image;
          console.log(img, 1);
          img = img.replace('https://mansour-dev.s3.us-east-2.amazonaws.com/','');
          img = img.replace('http://mansour-dev.s3.us-east-2.amazonaws.com/','');
          console.log(img, 2);
          formData.append("image", img);
        }

        // formData.append("test_drive", this.test_drive ? "1" : "0");
        formData.append("mobile_active", this.mobile_active ? "1" : "0");

        formData.append("slider_id", this.slider_id);
        formData.append("category_gallery_id", this.gallery_id);

        //console.log({ formData: formData });

        this.$httpCars
          .post(`/categories/${this.item.id}`, formData)
          .then(() => {
            this.$emit("saved");
            this.$vs.loading.close();
            //console.log(r);
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
            this.$emit("update");
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      },
      getModels() {
        this.$vs.loading();
        this.$httpCars
          .get("models")
          .then(r => {
            this.$vs.loading.close();
            this.models = r.data.data.models;
            //console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars
          .get("brands")
          .then(r => {
            this.$vs.loading.close();
            this.brands = r.data.data.brands;
            //console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      }
    },
    mounted() {
      this.getBrands();
      this.getModels();
      this.getGalleries();

      console.log("Category: ", this.item);
    }
  };
</script>

<style scoped lang="scss">
  .clr {
    clear: both;
  }

  .body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    label {
      font-size: 1rem;
      padding: 5px;
    }

    select {
      padding: 10px;
      border-radius: 5px;
      width: 90%;
      background-color: #fff;
      border: 1px solid #00000030;
    }

    .inputs {
      margin: 20px 0 20px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    }
  }

  .input {
    width: 90%;
    margin-top: 30px;
  }

  .switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
  }

  .checkboxes {
    margin: 10px;

    .checkbox {
      margin: 5px;
    }
  }

  .optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
  }

  h2 {
    margin: 30px;
  }

  .optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  h4 {
    padding: 20px;
  }

  .subtitle {
    margin-top: 50px;
    color: #ccc;
  }

  .input {
    width: 90%;
    margin-top: 10px;
  }
</style>
